import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlIconComponent } from './vl-icon.component';
import { ToastSuccessComponent } from './icons/toast-success/toast-success.component';
import { ToastErrorComponent } from './icons/toast-error/toast-error.component';
import { ToastWarningComponent } from './icons/toast-warning/toast-warning.component';

@NgModule({
  declarations: [
    VlIconComponent,
    ToastSuccessComponent,
    ToastErrorComponent,
    ToastWarningComponent,
  ],
  imports: [CommonModule],
  exports: [VlIconComponent],
})
export class VlIconModule { }
