import { Component, Input, OnInit, AfterViewInit, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';


@Component({
  selector: 'vl-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnChanges {
  @HostListener('mouseenter', ['$event']) onMouseEnter() {
    this.freezeTime();
  }
  @HostListener('mouseleave', ['$event']) onMouseLeave() {
    this.continueToTime();
  }

  @Input() id: string = '';
  @Input() type: 'success' | 'warning' | 'error' = 'success';
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() msg: string = '';
  @Input() time: number = 0;

  public timeInView: any = 0;
  private startMoment!: number;
  private lifeTimeInView: number = 0;

  constructor(private notificationSrc: NotificationService) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['icon']) {
      if (!this.icon) {
        if (this.type === 'success') this.icon = 'vli_toastSuccess';
        if (this.type === 'error') this.icon = 'vli_toastError';
        if (this.type === 'warning') this.icon = 'vli_toastWarning';
      }
    }
    if (changes['time']) {
      this.startMoment = Date.now();
      this.startTimeInView(this.time);
    }
  }

  freezeTime() {
    clearTimeout(this.timeInView);
    if (Date.now() - this.startMoment < this.time) this.lifeTimeInView = this.time - (Date.now() - this.startMoment);
  }

  continueToTime() {
    this.startTimeInView(this.lifeTimeInView);
  }

  startTimeInView(time: number) {
    this.timeInView = setTimeout(() => {
      this.close();
    }, time);
  }

  close() {
    clearTimeout(this.timeInView);
    this.notificationSrc.quitNotification(this.id);
  }
}
