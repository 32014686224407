import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { AuthService } from '../services/auth.service'

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService {
  constructor(
    private _authService: AuthService,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Obtengo el token actual de la sesion...
    const isFile = req.headers.has('file')
    const token = this._authService.token
    let request = req

    const headers: any = {
      'Content-Type': 'application/json',
    }

    // Validacion de existencia del token para agregar la cabecera...
    if (token && token.length) {
      //   // Modificación del request añadiendo la cabecera de autorizacion de videolink...
      headers.Authorization = `videolink ${token}`
    }

    if (isFile) {
      delete headers['Content-Type']
      let he = new HttpHeaders(headers).delete('file')
    }
    request = req.clone({
      setHeaders: headers,
    })

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        // Redirection al login en caso de status 401...
        if (err.status === 401) {
          this._authService.logout()
        } else if (err.status === 426) {
          console.log("Agente no actualizado")
          this._authService.logout()
        }

        return throwError(err)
      })
    )
  }
}
