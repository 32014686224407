import { Injectable, inject } from '@angular/core'
import { ApiService } from './api.service'
import { lastValueFrom } from 'rxjs'
import { tap } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class CompanyConfigService {
  public companyConfig!: CompanyConfig
  private api: ApiService = inject(ApiService)

  constructor() {}

  getCompanyConfig(): Promise<any> {
    return lastValueFrom(
      this.api.get('/ag/companyConfig').pipe(
        tap((response) => {
          this.companyConfig = response.data.companyConfig
        })
      )
    )
  }
  getUsersAvailable(subAbilityId: string): Promise<any> {
    const httpParams = new HttpParams({ fromObject: { subAbilityId } })
    return lastValueFrom(this.api.get('/ag/usersAvailables', httpParams))
  }
}
