import { EventEmitter, Injectable, inject } from '@angular/core'
import { ApiService } from './api.service'
import {
  BehaviorSubject,
  Subject,
  Subscription,
  interval,
  lastValueFrom,
} from 'rxjs'
import * as isEqual from 'fast-deep-equal'
import { AuthService } from './auth.service'
import { DataService } from './data.service'
import { CompanyConfigService } from './company-config.service'

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  intervalRunning = false
  sub!: Subscription
  _currentTicket: Ticket | undefined | null = null
  $changeCurrentTicket = new BehaviorSubject<Ticket | undefined | null>(null)
  // new config
  myTickets: Array<Ticket> = []
  historyTickets: Array<Ticket> = []
  disabledActions: boolean = false
  disabledEndTickets: boolean = false

  private _companyConfigSrc: CompanyConfigService = inject(CompanyConfigService)

  set currentTicket(ticket: Ticket | undefined | null) {
    if (isEqual(this._currentTicket, ticket)) return

    this._currentTicket = ticket

    if (
      !['free'].includes(
        this._companyConfigSrc.companyConfig?.transferMode ?? ''
      )
    ) {
      this.disabledActions =
        this._currentTicket?.status?.type === 'needResponse'
    }

    this.disabledEndTickets = Boolean(
      this.currentTicket &&
        !this._dataSrc.myData.groups?.includes(
          this.currentTicket?.initialInfo?.subAbility?.groupClose
        )
    )

    this.$changeCurrentTicket.next(ticket)
  }

  get currentTicket() {
    return this._currentTicket
  }

  changeView = new EventEmitter<Views>()

  _view: Views = 'ticket'

  set view(view: Views) {
    this._view = view
    this.changeView.emit(view)
  }

  get view(): Views {
    return this._view
  }

  constructor(private api: ApiService, private _dataSrc: DataService) {}

  setDataInterval(func: any) {
    if (!this.intervalRunning) {
      this.sub = interval(5000).subscribe(func)
      this.intervalRunning = true
    }
    return this.sub
  }
  stopInterval() {
    this.sub.unsubscribe()
    this.intervalRunning = false
  }
  /**
   * @deprecated
   * @returns
   */
  async getStatistics() {
    try {
      const result = await lastValueFrom(
        this.api.get<CustomResponse<TicketStatisticsResponseData>>(
          '/ag/currentStatistics'
        )
      )
      return result
    } catch (error: any) {
      throw error.error
    }
  }

  /**
   * @deprecated
   * @returns
   */
  async getNextTicket() {
    try {
      const result = await lastValueFrom(
        this.api.get<CustomResponse<TicketResponseData>>('/ag/ticket/next')
      )
      if (result.data.ticket) {
        this.currentTicket = result.data.ticket
        return true
      }
      return false
    } catch (error: any) {
      throw error.error
    }
  }

  /**
   *
   * @returns Return all tickets assigned to the current user
   */
  async getNextTicketLimit() {
    try {
      const result = await lastValueFrom(
        this.api.get<CustomResponse<TicketsResponseData>>(
          '/ag/ticket/next/limit'
        )
      )

      if (result.data.tickets?.length) {
        this.myTickets = result.data.tickets
          .map((ticket) => {
            ticket?.context?.sort((a, b) => (a.date < b.date ? 1 : -1))
            ticket?.notes?.sort((a, b) => (a.date < b.date ? 1 : -1))

            return ticket
          })
          .sort((a: Ticket, b: Ticket) => {
            const points: number[] = [0, 0]

            ;[a, b].map((ticket, i) => {
              const statusSLA = this.getStatusSLA(ticket)
              if (ticket.client.priority) points[i] += 3

              // console.log(ticket.client.priority)

              if (statusSLA === 2) points[i] += 2
              if (statusSLA === 1) points[i] += 1
            })

            return points[1] - points[0]
          })
      } else {
        this.myTickets = []
      }

      const ticketCurrentInfo = this.myTickets.find(
        (ticket) => ticket._id === this.currentTicket?._id
      )

      if (ticketCurrentInfo) {
        this.currentTicket = ticketCurrentInfo
      } else {
        this.currentTicket = null
      }

      return result?.data?.tickets?.length > 0
    } catch (error: any) {
      throw error.error
    }
  }

  async sendMessage(message: string, files: Array<string> = []) {
    try {
      const result = await lastValueFrom(
        this.api.post<CustomResponse<TicketResponseData>>(
          '/ag/ticket/message',
          {
            message,
            ticket: this.currentTicket?._id,
            files: files.length ? files : undefined,
          }
        )
      )
      if (result.data.ticket) {
        this.currentTicket = result.data.ticket
        return true
      }
      return false
    } catch (error: any) {
      console.log(error)
      return false
    }
  }

  /**
   * @deprecated
   * @returns
   */
  async getMyCurrentTicket() {
    try {
      const result = await lastValueFrom(
        this.api.get<CustomResponse<TicketResponseData>>('/ag/ticket/current')
      )
      if (result.data.ticket) {
        // this.currentTicket = result.data.ticket;
        this.myTickets.push(result.data.ticket)
        return true
      }
      return false
    } catch (error: any) {
      throw error.error
    }
  }

  async transferTicket(data: TransferTicketRequestData) {
    try {
      const result = await lastValueFrom(
        this.api.post<CustomResponse<TicketResponseData>>(
          '/ag/ticket/transfer',
          data
        )
      )
      if (result.data.ticket) {
        this.currentTicket = null
        this.getNextTicketLimit()
        return true
      }
      return false
    } catch (error) {
      return false
    }
  }

  async changeTicketStatus({
    status,
    comments = '',
  }: {
    status: TicketStatus
    comments: string
  }) {
    try {
      const result = await lastValueFrom(
        this.api.post<CustomResponse<TicketResponseData>>('/ag/ticket/status', {
          ticket: this.currentTicket?._id,
          status,
          comments,
        })
      )
      if (result.data.ticket) {
        if (
          !['free'].includes(
            this._companyConfigSrc.companyConfig?.transferMode ?? ''
          )
        ) {
          result.data.ticket.status.type === 'needResponse'
            ? (this.currentTicket = null)
            : (this.currentTicket = result.data.ticket)
        } else {
          this.currentTicket = result.data.ticket
        }

        this.getNextTicketLimit()

        return true
      }
      return false
    } catch (error) {
      return false
    }
  }
  async changeTicketStatusFinal({
    status,
    comments = '',
    reason = '',
  }: {
    status: TicketStatus
    comments: string
    reason: string
  }) {
    try {
      const result = await lastValueFrom(
        this.api.post<CustomResponse<TicketResponseData>>(
          '/ag/ticket/status/close',
          { ticket: this.currentTicket?._id, status, comments, reason }
        )
      )
      if (result.data.ticket) {
        this.currentTicket = null
        this.getNextTicketLimit()
        this.history()

        return true
      }
      return false
    } catch (error) {
      return false
    }
  }

  async history() {
    try {
      const result = await lastValueFrom(
        this.api.get<CustomResponse<TicketsHistoryResponseData>>('/ag/history')
      )
      if (result.data.history?.length) {
        this.historyTickets = result.data.history
        return true
      }
      return false
    } catch (error: any) {
      throw error.error
    }
  }

  async seenTicket(ticket: Ticket) {
    try {
      const result = await lastValueFrom(
        this.api.post<CustomResponse<TicketResponseData>>(
          `/ag/ticket/seen/${ticket._id}`
        )
      )
      console.log({ result })
      if (result.data.ticket) {
        console.log('ticket seen')

        this.getNextTicketLimit()
        return true
      }
      return false
    } catch (error: any) {
      throw error.error
    }
  }

  getStatusSLA(ticket: Ticket): number {
    // 0 disponible
    // 1 proximo a vencer
    // 2 Vencido

    const createdAtTime = new Date(ticket.createdAt)
    const expiredAtTime = new Date(ticket.expDate)

    createdAtTime.setMilliseconds(0)
    expiredAtTime.setMilliseconds(0)

    let SLA = expiredAtTime.getTime() - createdAtTime.getTime()
    const lastAlarmt = (30 * SLA) / 100

    SLA -= (ticket as any).timeAvailable

    SLA -= ticket.history?.reduce((prev, current) => {
      if (current?.stateOfRefTime?.withSLA) prev += current.timeAvailable

      return prev
    }, 0)

    if (SLA <= 0) return 2

    if (SLA > 0 && SLA <= lastAlarmt) return 1

    return 0
  }
  getStatusAlarm(ticket: Ticket): number {
    // 0 disponible
    // 1 proximo a vencer
    // 2 Vencido

    let statusAlarm = 0

    const currentGroup = ticket.group
    const {
      customAlarmConfig,
      withCustomAlarm,
      groupEntry,
      timeAvailable,
      stateOfRefTime,
      history = [],
    } = ticket

    if (!withCustomAlarm) return statusAlarm

    const hashToGroup: { [key: string]: number[] } = {}

    customAlarmConfig.forEach((item, i) => {
      hashToGroup[item.group]
        ? hashToGroup[item.group].push(i)
        : (hashToGroup[item.group] = [i])
    })

    const historyFormat = [
      {
        stateOfRefTime,
        timeAvailable,

        group: groupEntry,
        action: 'waiting',
      },
      ...history.map((action) => {
        const group = action?.transferTo?.group ?? action.group
        const { timeAvailable, stateOfRefTime } = action

        return {
          stateOfRefTime,
          timeAvailable,
          group,
          action: action.action,
        }
      }),
    ]

    const historyFilter = historyFormat.filter(
      (item) => item.group === currentGroup
    )
    const splitToTransfer: any[] = historyFilter
      .map((item, i) => {
        return item.action === 'transfer' ? i : null
      })
      .filter((item) => item !== null)

    let refIndexAlarm = 0
    if (!hashToGroup[currentGroup]) return 0

    if (
      splitToTransfer.length &&
      splitToTransfer.length <= hashToGroup[currentGroup].length
    ) {
      refIndexAlarm = hashToGroup[currentGroup].includes(splitToTransfer.length)
        ? splitToTransfer.length
        : splitToTransfer.pop()
    }
    if (
      splitToTransfer.length &&
      splitToTransfer.length > hashToGroup[currentGroup].length
    ) {
      refIndexAlarm =
        hashToGroup[currentGroup][hashToGroup[currentGroup].length - 1]
    }

    const configAlarmn = ticket.customAlarmConfig[refIndexAlarm]

    if (!configAlarmn) return statusAlarm

    let SLA = configAlarmn.timeAlarm * 3600000
    let warning = configAlarmn.timeWarning * 60 * 1000
    let time = 0

    time = configAlarmn.timeAvailable

    if (time > warning) {
    } else if (time <= warning && time > 0) {
      statusAlarm = 1
    } else {
      statusAlarm = 2
    }

    return statusAlarm
  }

  buildTimeString(milisecond: number): string {
    const milisecondAbs = Math.abs(milisecond)
    const hours = Math.floor(milisecondAbs / 3600000)
    const minutes = Math.floor((milisecondAbs % 3600000) / 60000)
    const seconds = Math.floor((milisecondAbs % 60000) / 1000)

    let direrent: string[] = []

    if (hours) direrent.push(`${milisecond >= 0 ? '' : '-'}${hours} H`)
    if (minutes) direrent.push(`${milisecond >= 0 ? '' : '-'}${minutes} Min`)
    if (seconds) direrent.push(`${milisecond >= 0 ? '' : '-'}${seconds} Seg`)

    return direrent.join(', ')
  }

  async reassignTicket(body: {
    ticketID: string
    agentID: string
    comment: string
  }) {
    try {
      await lastValueFrom(this.api.put('/ag/reasingTicket', body))
      this.currentTicket = null
      this.getNextTicketLimit()
    } catch (error) {
      console.log(error)
    }
  }
  async addNote(body: { ticketID: string; msg: string }) {
    try {
      await lastValueFrom(this.api.put('/ag/note-ticket', body))
      this.getNextTicketLimit()
    } catch (error) {
      console.log(error)
    }
  }
}
