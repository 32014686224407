import { Observable, lastValueFrom } from 'rxjs'
import { ApiService } from './api.service'
import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { StorageService } from './storage.service'
import { DataService } from './data.service'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLogin = false
  _token: string = ''

  set token(value: string) {
    this._token = value
    this._storageService.set('vlTkn', value)
    this.isLogin = true
  }

  get token() {
    return this._token
  }

  constructor(
    private _apiService: ApiService,
    private _storageService: StorageService,
    private _dataService: DataService,
    private router: Router
  ) {
    this.getDataFromToken()
  }

  async resetPassword(
    data: ResetPasswordRequest
  ): Promise<ResetPasswordResponse> {
    try {
      const result = await lastValueFrom(
        this._apiService.post<ResetPasswordResponse>('/auth/v2/resetPwr', {
          ...data,
          url: location.origin + '/auth/change-password',
        })
      )
      return result
    } catch (error: any) {
      if (error instanceof HttpErrorResponse) {
        console.log({ error: error.error })
      }
      return error.error
    }
  }

  changePassword(data: ChangePasswordRequest): Observable<void> {
    return this._apiService.put('/auth/v2/changePwr', data)
  }

  async login(data: LoginRequest) {
    try {
      const result: any = await lastValueFrom(
        this._apiService.post<LoginResponse>('/auth/v2/signIn', {
          ...data,
          signIn: 'agent',
        })
      )
      if (result?.data?.token) this.iAmLogin(result?.data?.token)
      else this.clearAll()
      return result?.data?.token
    } catch (error: any) {
      if (error instanceof HttpErrorResponse) {
        console.log({ error: error.error })
      }
      throw error.error
    }
  }

  iAmLogin(token: string) {
    this.token = token
    this.isLogin = true
    this.getDataFromToken()
  }

  getDataFromToken() {
    try {
      const token = this._storageService.get('vlTkn')
      this.token = token
      if (!token || !token.length) {
        this.isLogin = false
        return
      }
      const data = JSON.parse(atob(token.split('.')[1]))
      this._dataService.myData.name = data.firstname + ' ' + data.lastname
      this._dataService.myData.email = data.email
      this._dataService.myData.company = data.company
      this._dataService.myData.company_name = data.company_name
      this._dataService.myData.user = data.user
      this._dataService.myData.first_name = data.firstname
      this._dataService.myData.last_name = data.lastname
      this._dataService.myData.level = data.level
      this._dataService.myData.permissions = data.permissions
      this._dataService.$changeMyData.next(this._dataService.myData)
    } catch (error) {
      console.log({ error })
      this.isLogin = false
    }
  }

  clearAll() {
    this.isLogin = false
    this.token = ''
  }

  logout() {
    this.clearAll()
    this._storageService.clearAll()
    this.router.navigate(['/'])
  }

  haveAnyPermission(...permissions: any): boolean {
    try {
      this.getDataFromToken()

      const dbPermisions = this._dataService.myData?.permissions ?? []

      if (!dbPermisions.length) throw {}

      let matchs = permissions.map((permission: string) =>
        dbPermisions.includes(permission)
      )
      return matchs.some((match: boolean) => match === true)
    } catch (error) {
      return false
    }
  }
}
