import { environment } from './../../../environments/environment'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  get<T>(
    path: string,
    params: HttpParams = new HttpParams(),
    header: HttpHeaders = new HttpHeaders()
  ): Observable<T> {
    return this.http.get<T>(`${environment.api}${path}`, {
      params: params,
      headers: header,
    })
  }
  post<T>(
    path: string,
    body: Object = {},
    params: HttpParams = new HttpParams(),
    header: HttpHeaders = new HttpHeaders()
  ): Observable<T> {
    return this.http.post<T>(`${environment.api}${path}`, body, {
      params: params,
      headers: header,
    })
  }
  put<T>(
    path: string,
    body: Object = {},
    params: HttpParams = new HttpParams(),
    header: HttpHeaders = new HttpHeaders()
  ): Observable<T> {
    return this.http.put<T>(`${environment.api}${path}`, body, {
      headers: header,
      params: params,
    })
  }

  delete<T>(
    path: string,
    header: HttpHeaders = new HttpHeaders()
  ): Observable<T> {
    return this.http.delete<T>(`${environment.api}${path}`, {
      headers: header,
    })
  }
}
