import { NgModule, isDevMode } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LayoutComponent } from './shared/components/layout/layout.component'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { AuthInterceptorService } from './shared/interceptors/auth-interceptor.service'
import { DialogService } from './shared/services/dialog.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LoadingService } from './shared/services/loading.service'
import { LoadingModule } from './shared/components/loading/loading.module'
import { TicketsService } from './shared/services/tickets.service'
import { VlNotificationModule } from './shared/components/vl-notification/vl-notification.module'
import { DataService } from './shared/services/data.service';
import { ServiceWorkerModule } from '@angular/service-worker'

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    VlNotificationModule,
    LoadingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    DialogService,
    LoadingService,
    TicketsService,
    DataService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
