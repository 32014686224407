import { OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';

import { ToastErrorComponent } from './icons/toast-error/toast-error.component';
import { ToastSuccessComponent } from './icons/toast-success/toast-success.component';
import { ToastWarningComponent } from './icons/toast-warning/toast-warning.component';

@Component({
  selector: 'vl-custom-icon',
  templateUrl: './vl-icon.component.html',
  styleUrls: ['./vl-icon.component.scss'],
})
export class VlIconComponent implements OnChanges {
  @Input('name') name: string = '';
  @Input() isCustom?: boolean = true;

  public iconsRef = [


    { name: 'vli_toastSuccess', component: ToastSuccessComponent },
    { name: 'vli_toastError', component: ToastErrorComponent },
    { name: 'vli_toastWarning', component: ToastWarningComponent },
  ];
  public currentComponent: null | any = null;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['name']) this.setCurrentComponent();
  }

  setCurrentComponent() {
    const match = this.iconsRef.find((icon) => icon.name === this.name);
    if (match) {
      this.currentComponent = match.component;
      this.isCustom = true;
    } else {
      this.currentComponent = null;
      this.isCustom = false;
    }
  }
}
