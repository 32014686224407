import { Component, OnDestroy, OnInit } from '@angular/core';
import { vla_toast } from './../../animations/toastInScreem';

import { Subject, takeUntil } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'vl-notification',
  templateUrl: './vl-notification.component.html',
  styleUrls: ['./vl-notification.component.scss'],
  animations: [vla_toast],
})
export class VlNotificationComponent implements OnDestroy {
  notifications: vlToast[] = [];
  private _unSuscribeAll: Subject<any> = new Subject();
  constructor(private notificationSrc: NotificationService) {
    this.notificationSrc.notifications.pipe(takeUntil(this._unSuscribeAll)).subscribe((data) => (this.notifications = data));
  }
  ngOnDestroy(): void {
    this._unSuscribeAll.next({});
    this._unSuscribeAll.complete();
  }
}
