import { Component, ComponentRef, EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  currentComponent: ComponentRef<any> | null = null;
  currentData: any = {};
  styles = {}
  clickOutside = true;
  showCloseButton = true;
  $openEvent: EventEmitter<any> = new EventEmitter();
  $closeEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  open(component: any, config: DialogConfig = {}) {
    try {

      this.currentData = config.data || {};
      this.styles = config.styles || {};
      this.clickOutside = config.closeOnOutsideClick == undefined ? true : config.closeOnOutsideClick;
      this.currentComponent = component;
      this.showCloseButton = config.showCloseButton == undefined ? true : config.showCloseButton;
      this.$openEvent.emit(
        {
          component: component,
          data: this.currentData,
          styles: this.styles,
          clickOutside: this.clickOutside
        }
      );

    } catch (error) {
      console.log("error", error);
    }
  }

  close() {
    this.currentComponent = null;
    this.currentData = {};
    this.styles = {};
    this.$closeEvent.emit();
    // console.log({ c: this.currentComponent })
  }
}
