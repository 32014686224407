import { Injectable, inject } from '@angular/core'
import { Subject, lastValueFrom } from 'rxjs'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root',
})
export class DataService {
  set myData(data: SelfData) {
    this._myData = data
    this.$changeMyData.next(data)
  }
  get myData() {
    return this._myData
  }

  $changeMyData = new Subject<SelfData>()
  _myData: SelfData = {}
  companyData: CompanyData = {}
  abilities: Ability[] = []

  constructor() {}
}
