import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }


  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }


  get(key: string) {
    let token = localStorage.getItem(key)
    if (!token) {
      return null;
    }
    return JSON.parse(token);
  }


  clearAll() {
    localStorage.clear();
  }

}
